import AccordionSection from "components/common/AccordionSection";
import DemoFormSection from "components/common/DemoFormSection";
import FourColumnFeatures from "components/common/FourColumnFeatures";
import Carrot from "components/common/icon-components/Carrot";
import Dome from "components/common/icon-components/Dome";
import IconTeamWork from "components/common/icon-components/IconTeamWork";
import IconTrust from "components/common/icon-components/IconTrust";
import IconWarranty from "components/common/icon-components/IconWarranty";
import RhombusButton from "components/common/RhombusButton";
import GlobalLayout from "components/page/GlobalLayout";
import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  PageTitle,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { Helmet } from "react-helmet";

const Hero = styled(SectionContainer)`
  background: var(--gradient-base);
  text-align: center;
  align-items: center;
  min-height: 650px;
`;

const HeroText = styled(TextContainer)`
  align-items: center;
  color: var(--white);
  gap: 0;
  h3 {
    font-size: 30px;
  }

  p {
    font-size: 24px;
    margin: 1.25rem 0;
  }

  @media (min-width: 1440px) {
    h1 {
      font-size: 65px;
    }
  }
`;

const DescriptionBlade = styled(SectionContainer)`
  background: var(--blue-900);
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
  > div {
    justify-content: center;
  }
`;

export default function FSTECLandingPage() {
  const renderContent = graphQLData => {
    const imageProps = {
      imgStyles: {
        borderRadius: "12px",
        width: "669px",
        maxWidth: "calc(100% - 2.5rem)",
        maxHeight: "416px",
      },
    };

    return (
      <GlobalLayout>
        <Helmet>
          <title>FSTEC - Special Event</title>
          <meta
            name="description"
            content="Join Rhombus for an exclusive dining experience at the renowned Nick & Sam's Steakhouse in Dallas, Texas."
          />
          <meta name="robots" content="noindex, nofollow"></meta>
        </Helmet>
        <Hero>
          <SectionInner>
            <HeroText>
              <PreTitle>FSTEC 2024</PreTitle>
              <PageTitle>Executive Dinner at Nick & Sam's </PageTitle>
              <MainParagraph>
                Monday, September 16 @ 5:30 PM, Transportation Provided
              </MainParagraph>
              <RhombusButton
                title="Save Your Seat"
                renderIcon={() => <Carrot />}
                className={css`
                  :hover {
                    background: var(--white) !important;
                    color: var(--blue-900) !important;
                  }
                `}
              />
            </HeroText>
          </SectionInner>
        </Hero>
        <DescriptionBlade>
          <SectionInner>
            <TextContainer width="1000px">
              <MainParagraph color="var(--white)">
                Join us for an exclusive dining experience at the{" "}
                <b>renowned Nick & Sam's Steakhouse</b> in Dallas, Texas, where
                technology executives from the foodservice industry will gather
                for an evening of culinary excellence and insightful
                conversation. As a special highlight, the evening will feature a{" "}
                <b>
                  book signing with the celebrated Executive Chef Samir
                  Dhurandhar
                </b>
                , offering a unique keepsake.
              </MainParagraph>
            </TextContainer>
          </SectionInner>
        </DescriptionBlade>
        <SectionContainer>
          <SectionInner style={{ flexDirection: "column", gap: 0 }}>
            <TextContainer style={{ textAlign: "center" }}>
              <PreTitle>A SPECIAL EVENING ALL ABOUT</PreTitle>
              <FancyTitle as="h2">
                Good Food & Better Physical Security
              </FancyTitle>
            </TextContainer>
            <FourColumnFeatures
              layoutSize="compact"
              style={{ padding: 0 }}
              features={[
                {
                  icon: () => <IconWarranty />,
                  title: "A Night at Nick & Sam’s",
                  blurb:
                    "Indulge in a meal at this iconic establishment, known for its legendary steaks, rare wagyu beef, and exceptional wine selection.",
                },
                {
                  icon: () => (
                    <Dome color="var(--blue-500)" height="35" width="31" />
                  ),
                  title: "Modern Physical Security",
                  blurb:
                    "Discover how physical security goes beyond surveillance with rich data analytics and AI technology to enhance operational efficiency.",
                },
                {
                  icon: () => <IconTrust height="35" />,
                  title: "Meet Samir Dhurandhar",
                  blurb:
                    "Executive Chef at Nick & Sam’s Steakhouse, has led the kitchen since 1999, earning acclaim for his innovative cuisine and exceptional dining experiences.",
                },
                {
                  icon: () => <IconTeamWork height="35" />,
                  title: "Intimate Executive Group",
                  blurb:
                    "An invite-only, executive guest list ensures that you leave this evening inspired by industry leaders from some of the largest restaurant groups.",
                },
              ]}
              title=""
            />
          </SectionInner>
        </SectionContainer>
        <AccordionSection
          color="var(--nuetral-100)"
          fancy={false}
          data={{
            title: "Schedule of Events",
            items: [
              {
                ...imageProps,
                img: graphQLData.image1,
                title: "5:30 PM: Bus Departs Convention Center",
                p:
                  "For those needing transportation, the bus will depart from the Gaylord Texan Resort & Convention Center promptly at 5:30 PM.",
              },
              {
                ...imageProps,
                img: graphQLData.image2,
                title: "6:00 PM: Welcome & Networking",
                p:
                  "We encourage all guests to arrive on time, we will kick off the evening in the private wine room to meet and connect with industry professionals over drinks.",
              },
              {
                ...imageProps,
                img: graphQLData.image3,
                title: "6:30 PM: Seated Dinner",
                p:
                  "Enjoy an unforgettable dinner featuring a menu of exquisite dishes  with options like crab cake, shrimp cocktail, a selection of prime steaks, garlic mashed potatoes, steamed asparagus, and decadent desserts, such as crème brûlée or s'mores fudge brownie.",
              },
              {
                ...imageProps,
                img: graphQLData.image4,
                title: "8:00 PM: Fireside Chat",
                p:
                  "In addition to savoring world-class cuisine, engage in a fireside chat with a special guest who will share their experiences and insights on the intersection of technology and operations in foodservice.",
              },
            ],
          }}
        />
        <DemoFormSection
          useEventClosedFallback
          fancy={false}
          useFormExpansion
          formTitle=""
          color="var(--gradient-dark)"
          data={{
            title: "Join the Fun! RSVP to Save Your Seat",
            description:
              "Please note this is an invite-only event, and invitations are not transferrable.",
            id: "",
          }}
        />
      </GlobalLayout>
    );
  };
  const GET_IMAGES = graphql`
    query {
      image1: file(
        relativePath: {
          eq: "components/landing-pages/img/fstec/convention-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image2: file(
        relativePath: {
          eq: "components/landing-pages/img/fstec/networking-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image3: file(
        relativePath: {
          eq: "components/landing-pages/img/fstec/dinner-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      image4: file(
        relativePath: {
          eq: "components/landing-pages/img/fstec/fireside-chat-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
    }
  `;

  return <StaticQuery render={renderContent} query={GET_IMAGES} />;
}
