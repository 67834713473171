import React from "react";

const IconTrust = ({ color = "#2A7DE1", width = "33", height = "23" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7474 6.54102C15.7435 6.54624 15.7448 6.55277 15.75 6.55669L26.0742 13.591C26.7519 14.0533 27.3826 14.8002 27.2656 15.6137C27.1667 16.2954 26.744 17.4954 24.0908 16.6414C24.0895 16.6414 24.0895 16.6414 24.0882 16.6401L18.7167 13.1575"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.1385 20.3215C17.1398 20.3228 17.1398 20.3228 17.1398 20.3241C17.1502 20.3646 16.9109 22.5413 14.5815 21.6155C14.5815 21.6142 14.5815 21.6142 14.5815 21.6142C14.5685 21.609 6.56713 17.9018 5.76595 14.4636C5.76595 14.4623 5.76465 14.461 5.76465 14.461V3.2925C5.76465 3.28989 5.76465 3.28728 5.76855 3.28597C5.94543 3.17106 14.4423 -0.866478 20.5032 2.06505C20.5097 2.06766 20.511 2.07549 20.5071 2.08072"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.3081 18.7933C20.3094 18.7946 20.3094 18.7946 20.3107 18.7959C20.3302 18.8443 20.9558 21.4506 18.1647 20.9714C18.0567 20.9283 17.9501 20.8865 17.8421 20.8421L14.666 18.668"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.0919 16.6321C24.0932 16.6334 24.0945 16.6334 24.0945 16.6347C24.1101 16.6817 24.1816 18.7971 21.8483 19.0583C21.3098 19.1183 20.7662 18.9799 20.3058 18.6939L16.668 16.4336"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4141 4.5315C27.4141 4.5315 20.1853 0.658496 15.8048 2.47878C15.494 2.60806 15.1701 2.7073 14.8476 2.80001L8.67487 4.58635C8.0805 4.75871 7.75924 5.41814 8.01156 5.98617C8.55912 7.21493 10.2577 8.7401 15.5291 6.61295L27.4141 14.6332V4.5315Z"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.7161 16.5413V16.5413C28.4493 16.5413 27.4141 15.5005 27.4141 14.23V5.50463C27.4141 4.23278 28.4493 3.19336 29.7161 3.19336V3.19336C30.9829 3.19336 32.0182 4.23278 32.0182 5.50463V14.23C32.0182 15.5005 30.9829 16.5413 29.7161 16.5413Z"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.30208 15.7786V15.7786C2.03529 15.7786 1 14.7378 1 13.4673V4.74193C1 3.47008 2.03529 2.43066 3.30208 2.43066V2.43066C4.56888 2.43066 5.60416 3.47008 5.60416 4.74193V13.4673C5.60416 14.7378 4.56888 15.7786 3.30208 15.7786Z"
        stroke={color}
        stroke-width="1.96324"
      />
    </svg>
  );
};

export default IconTrust;
