import React from "react";

const IconTeamWork = ({ color = "#2A7DE1", width = "40", height = "33" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40 33"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5404 12.6726C12.5404 14.8448 10.7833 16.605 8.6151 16.605C6.44845 16.605 4.69141 14.8448 4.69141 12.6726C4.69141 10.5005 6.44845 8.74023 8.6151 8.74023C10.7833 8.74023 12.5404 10.5005 12.5404 12.6726Z"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        d="M20.3012 15.1568C18.688 19.7071 16.0691 19.6073 14.9905 20.1174C14.7469 20.2315 14.592 20.4787 14.592 20.7496C14.5904 22.5367 14.5081 29.9658 14.5018 30.4903C14.5018 30.4966 14.486 30.7453 14.486 30.7501C14.4749 31.1288 14.1744 31.1842 13.7981 31.1842H5.57586C5.18681 31.1842 5.0682 30.9466 5.03024 30.7644C5.02075 30.7121 5.01127 30.5188 5.01285 30.4649C5.02866 30.0134 5.19156 26.1127 5.08243 25.7483C4.96382 25.3538 4.78037 25.4187 4.78037 25.4187C4.43876 25.3934 4.42927 25.772 4.42927 25.772L4.3929 29.6727C4.3929 29.7234 4.38816 29.7757 4.3755 29.8248C4.09084 31.0068 3.01067 31.162 2.73075 31.1826C2.69437 31.1858 2.658 31.1858 2.62163 31.1826C2.14718 31.1462 1.80083 30.9418 1.55254 30.6994C1.17772 30.3318 0.987942 29.8169 1.00059 29.2925L1.0512 27.1203C1.07967 25.894 1.23465 24.6725 1.53039 23.481C1.72808 22.6793 1.99061 21.8808 2.30691 21.4641C2.31323 21.4562 2.31956 21.4467 2.32431 21.4388C3.9659 18.7786 7.11782 18.1496 7.49738 18.0831C7.5211 18.0783 7.54482 18.0752 7.56854 18.0736L11.032 17.8201C11.0542 17.8185 11.0747 17.8169 11.0969 17.8138C12.6831 17.5571 13.0421 17.2862 13.8566 16.86C14.0258 16.7712 14.1855 16.6572 14.3484 16.5653C16.5767 15.3136 18.5473 9.86816 18.5473 9.86816"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4141 12.6726C27.4141 14.8448 29.1711 16.605 31.3393 16.605C33.506 16.605 35.263 14.8448 35.263 12.6726C35.263 10.5005 33.506 8.74023 31.3393 8.74023C29.1711 8.74023 27.4141 10.5005 27.4141 12.6726Z"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M37.6466 21.4645C37.6403 21.4566 37.6356 21.4471 37.6292 21.4391C35.9876 18.779 32.8373 18.15 32.4562 18.0819C32.4324 18.0787 32.4103 18.0755 32.3866 18.0739L28.9231 17.8204C28.901 17.8189 28.8788 17.8173 28.8583 17.8125C27.2704 17.5559 26.9114 17.2865 26.0986 16.8603C25.9278 16.77 25.7696 16.6575 25.6051 16.564C23.3768 15.314 21.9329 10.675 21.9329 10.675C21.2244 8.24454 19.48 8.7959 19.48 8.7959C17.5174 9.32191 19.0957 12.8931 19.2475 13.2274C19.2554 13.2448 19.2633 13.2622 19.2697 13.2797C20.8812 17.8299 23.8845 19.6076 24.963 20.1162C25.2066 20.2318 25.3616 20.479 25.3616 20.7483C25.3647 22.5371 25.4454 29.9662 25.4517 30.4906C25.4533 30.497 25.4691 30.7441 25.4691 30.7504C25.4802 31.1291 25.7791 31.1846 26.1571 31.1846H34.3777C34.7683 31.1846 34.8869 30.9469 34.9233 30.7647C34.9344 30.7124 34.9423 30.5175 34.9407 30.4653C34.9265 30.0121 34.762 26.113 34.8711 25.7486C34.9897 25.3541 35.1732 25.4191 35.1732 25.4191C35.5164 25.3937 35.5243 25.7724 35.5243 25.7724L35.5606 29.6731C35.5622 29.7238 35.567 29.7761 35.578 29.8252C35.8627 31.0071 36.9444 31.1624 37.2228 31.183C37.2592 31.1846 37.2955 31.1861 37.3335 31.183C37.8064 31.1465 38.1527 30.9422 38.401 30.6997C38.7758 30.3322 38.9672 29.8157 38.9545 29.2912L38.9023 27.1207C38.8739 25.8944 38.7205 24.6728 38.4247 23.4814C38.2255 22.6797 37.9629 21.8812 37.6466 21.4645Z"
        stroke={color}
        stroke-width="1.96324"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8572 3.24188V1V3.24188V5.48375V3.24188Z"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1221 5.48341C25.9967 4.60726 26.7052 3.89746 26.7052 3.89746C26.7052 3.89746 25.9967 4.60726 25.1221 5.48341C24.2476 6.35957 23.5391 7.06936 23.5391 7.06936C23.5391 7.06936 24.2476 6.35957 25.1221 5.48341Z"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5928 5.14259C13.7183 4.26644 13.0098 3.55664 13.0098 3.55664C13.0098 3.55664 13.7183 4.26644 14.5928 5.14259C15.4674 6.01874 16.1759 6.72854 16.1759 6.72854C16.1759 6.72854 15.4674 6.01874 14.5928 5.14259Z"
        stroke={color}
        stroke-width="1.96324"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconTeamWork;
